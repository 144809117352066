var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"12","lg":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',{staticClass:"card-title mr-3"},[_vm._v(" "+_vm._s(_vm.$t("featureEditPage.pageTitle")))])]),_c('CCardBody',[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("common.editCaption"))+" ")]),_c('CForm',{staticClass:"submit_form",on:{"submit":function($event){$event.preventDefault();}}},[_c('CRow',[_c('CCol',{staticClass:"label"},[_c('strong',{staticClass:"required-mark"},[_vm._v(" "+_vm._s(_vm.$t("featureEditPage.typeLabel")))])]),_c('CCol',{attrs:{"sm":"2"}},[_c('CSelect',{attrs:{"disabled":true,"value":_vm.selectedType,"options":_vm.FEATURE_TYPE_LABEL},on:{"update:value":function($event){_vm.selectedType=$event}}})],1)],1),_c('CRow',[_c('CCol',{staticClass:"label"},[_c('strong',{staticClass:"required-mark"},[_vm._v(_vm._s(_vm.$t("common.name")))])]),_c('CCol',[_c('CInput',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnterKeyDown($event)}},model:{value:(_vm.editJa.name),callback:function ($$v) {_vm.$set(_vm.editJa, "name", $$v)},expression:"editJa.name"}}),(
                    _vm.$v.editJa.name.$dirty &&
                    !_vm.$v.editJa.name.required
                  )?_c('p',{staticClass:"text-danger input-info"},[_vm._v(" "+_vm._s(_vm.$t("validations.required", { vName: _vm.$t("common.name")}))+" ")]):_vm._e(),(
                    _vm.$v.editJa.name.$dirty &&
                    !_vm.$v.editJa.name.maxLength
                  )?_c('p',{staticClass:"text-danger input-info"},[_vm._v(" "+_vm._s(_vm.$t("validations.maxLength", { vName: _vm.$t("common.name"),vMax: _vm.$v.editJa.name.$params.maxLength.max }))+" ")]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{staticClass:"label"},[_c('strong',[_vm._v(_vm._s(_vm.$t("common.description")))])]),_c('CCol',[_c('CTextarea',{staticClass:"mb-2",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnterKeyDown($event)}},model:{value:(_vm.editJa.description),callback:function ($$v) {_vm.$set(_vm.editJa, "description", $$v)},expression:"editJa.description"}}),(
                    _vm.$v.editJa.description.$dirty &&
                    !_vm.$v.editJa.description.maxLength
                  )?_c('p',{staticClass:"text-danger input-info"},[_vm._v(" "+_vm._s(_vm.$t("validations.maxLength", { vName: _vm.$t("common.description"),vMax: _vm.$v.editJa.description.$params.maxLength.max }))+" ")]):_vm._e()],1)],1),_c('CButton',{staticClass:"px-5 mt-4 float-left",class:_vm.activeStatus,attrs:{"color":"info"},on:{"click":function($event){return _vm.onUpdateClicked()}}},[_c('i',{staticClass:"cil-task"}),_vm._v(" "+_vm._s(_vm.$t("common.update"))+" ")])],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.onBackClicked()}}},[_c('i',{staticClass:"icon cil-arrow-left mr-2"}),_vm._v(_vm._s(_vm.$t("common.back")))])],1)],1)],1)],1),_c('SuccessModal',{attrs:{"successModal":_vm.successModal,"car":[]},on:{"close":function($event){_vm.successModal = $event}}}),_c('SuccessModal',{attrs:{"successModal":_vm.deleteSuccessModal,"car":[]},on:{"close":function($event){_vm.deleteSuccessModal = $event}}}),_c('ErrorModal',{attrs:{"errorModal":_vm.errorModal,"errorMessage":_vm.errorMessage},on:{"close":function($event){_vm.errorModal = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }